<template>
  <div>
    <Disclosure
      as="div"
      class="relative bg-indigo-600 pb-32 overflow-hidden"
      v-slot="{ open }"
    >
      <div
        :class="[
          open ? 'bottom-0' : 'inset-y-0',
          'absolute flex justify-center inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden lg:inset-y-0',
        ]"
        aria-hidden="true"
      >
        <div class="flex-grow bg-indigo-600 bg-opacity-75" />
        <svg
          class="flex-shrink-0"
          width="1750"
          height="308"
          viewBox="0 0 1750 308"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity=".1"
            d="M1465.84 308L16.816 0H1750v308h-284.16z"
            fill="#fff"
          />
          <path
            opacity=".05"
            d="M1733.19 0L284.161 308H0V0h1733.19z"
            fill="#fff"
          />
        </svg>
        <div class="flex-grow bg-indigo-600 bg-opacity-75" />
      </div>
      <header class="relative py-10">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">Settings</h1>
        </div>
      </header>
    </Disclosure>

    <main class="relative -mt-32">
      <div class="max-w-screen-xl mx-auto pb-6 px-4 sm:px-6 lg:pb-16 lg:px-8">
        <div class="bg-white rounded-lg shadow overflow-hidden">
          <div
            class="
              divide-y divide-gray-200
              lg:grid lg:grid-cols-12
              lg:divide-y-0 lg:divide-x
            "
          >
            <aside class="py-6 lg:col-span-3">
              <nav class="space-y-1">
                <a
                  v-for="item in subNavigation"
                  :key="item.name"
                  :href="item.href"
                  :class="[
                    item.current
                      ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                      : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'group border-l-4 px-3 py-2 flex items-center text-sm font-medium',
                  ]"
                  :aria-current="item.current ? 'page' : undefined"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-teal-500 group-hover:text-teal-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  <span class="truncate">
                    {{ item.name }}
                  </span>
                </a>
              </nav>
            </aside>

            <LoadingSpinner v-if="isLoading" class="divide-y divide-gray-200 lg:col-span-9"/>
            <div
              class="divide-y divide-gray-200 lg:col-span-9"
              v-if="!isLoading"
            >
              <div class="py-6 px-4 sm:p-6 lg:pb-8">
                <div>
                  <h2 class="text-lg leading-6 font-medium text-gray-900">
                    Videos
                  </h2>
                  <p class="mt-1 text-sm text-gray-500">
                    These are the videos that will appear on your public
                    profile. Please provide a YouTube or Vimeo ID for <strong>up to {{userData.MaxNumberOfVideos}} videos</strong>.
                  </p>
                </div>
                <!-- Success message -->
                  <div v-if="showSuccess" class="rounded-md bg-green-50 p-4 mb-8 mt-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: solid/check-circle -->
                        <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div class="ml-3">
                        <h3 class="text-sm font-medium text-green-800">
                          Successfully saved changes
                        </h3>
                      </div>
                    </div>
                  </div>
                  <!-- Failure message -->
                    <div v-if="showFailure" class="rounded-md bg-red-50 p-4 mb-8">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <!-- Heroicon name: solid/x-circle -->
                          <svg
                            class="h-5 w-5 text-red-400"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                        <div class="ml-3">
                          <h3 class="text-sm font-medium text-red-800">Failed to save changes</h3>
                          <div class="mt-2 text-red-700">
                            <p class=" text-red-700 text-sm ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                <!-- Gallery -->
                <section class="mt-8 pb-16" aria-labelledby="gallery-heading">
                  <div class="flex flex-col">
                    <div class="grid grid-cols-3 py-4 px-4 -mb-2" v-for="video in videoList" :key="video.id">
                      <div>
                        <img :src="video.thumbnail ? video.thumbnail : 'https://i3.ytimg.com/vi/NpEaa2P7qZI/hqdefault.jpg'" class="w-full md:w-2/3 mt-2">
                      </div>
                      <div class="col-span-2">
                        <div class="grid grid-cols-2 gap-x-3">
                          <div class="mb-4">
                            <label for="video-type" class="block text-sm font-medium text-gray-700">Video Type</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                              <select id="video-type" name="video-type" class="mt-1 block w-full pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                @change="updateVideoInList(video.id, 'type', $event.target.value)">
                                <option disabled :selected="!video.type">-- Please Select --</option>
                                <option :selected="video.type == 'youtube'" value="youtube">Youtube</option>
                                <option :selected="video.type == 'vimeo'" value="vimeo">Vimeo</option>
                              </select>
                            </div>
                          </div>
                          <div class="mb-4">
                            <label for="video-id" class="block text-sm font-medium text-gray-700">Video ID</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                              <input type="text" name="video-id" id="video-id" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" 
                              placeholder=""  :value="video.videoId" @input="updateVideoInList(video.id, 'videoId', $event.target.value)"/>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="mb-4">
                            <label for="video-title" class="block text-sm font-medium text-gray-700">Video Title</label>
                            <div class="mt-1 relative rounded-md shadow-sm">
                              <input type="text" name="video-title" id="video-title" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" 
                              placeholder=""  :value="video.title" @input="updateVideoInList(video.id, 'title', $event.target.value)"/>
                            </div>
                        </div> -->
                        <button
                          type="submit"
                          class="group flex cursor-pointer focus:outline-none rounded-sm focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          v-on:click="clearDataForVideo(video.id)"
                          >
                          <div class="bg-red-600 group-hover:bg-red-700 inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white">
                             <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                          </div>
                          <div class="-mb-2 ml-2 group-hover:text-red-700 text-sm">
                            Remove Video
                          </div>
                        </button>
                      </div>
                      
                        <hr class="mt-5 col-span-3"/>
                    </div>
                    <div class="mt-4 py-4 px-4 flex justify-end sm:px-6">
                    <button
                      type="submit"
                      class="
                        ml-5
                        border border-transparent
                        rounded-md
                        shadow-sm
                        py-2
                        px-4
                        inline-flex
                        justify-center
                        text-sm
                        font-medium
                        text-white
                        focus:outline-none
                        focus:ring-2 focus:ring-offset-2 focus:ring-blue-400
                        bg-indigo-600 hover:bg-blue-700
                      "
                      v-on:click="saveVideoList()"
                      >
                      Save
                    </button>
                  </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <div v-if="!isPremium" class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 hidden">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="p-2 rounded-lg bg-yellow-400 shadow-lg sm:p-3">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span class="flex p-2 rounded-lg bg-yellow-600">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
                <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </span>
            <p class="ml-3 font-medium text-white truncate">
              <span class="md:hidden text-black">
                Unlock additional features with a paid membership!
              </span>
              <span class="hidden md:inline text-black">
                 Unlock additional features with a paid membership!
              </span>
            </p>
          </div>
          <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            <a href="/Billing" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-black-600 bg-white hover:bg-yellow-50">
              See Pricing Options
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from "@headlessui/vue";
import { SearchIcon } from "@heroicons/vue/solid";
import {
  BellIcon,
  CreditCardIcon,
  KeyIcon,
  MenuIcon,
  UserCircleIcon,
  PhotographIcon,
  VideoCameraIcon,
  XIcon,
} from "@heroicons/vue/outline";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import axios from "axios";
import { NopService } from '../services/nopService';
import { CookieService } from '../services/cookieService';

const user = {
  name: "John Cooper",
  handle: "johncooper",
  email: "johncooper@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
};
const navigation = [
  { name: "Dashboard", href: "#", current: true },
  { name: "Jobs", href: "#", current: false },
  { name: "Applicants", href: "#", current: false },
  { name: "Company", href: "#", current: false },
];
const subNavigation = [
  { name: "Profile", href: "/Dashboard", icon: UserCircleIcon, current: false },
  { name: "Media", href: "/Media", icon: PhotographIcon, current: false },
  { name: "Videos", href: "/Videos", icon: VideoCameraIcon, current: true },
  { name: "Password", href: "/Password", icon: KeyIcon, current: false },
  // { name: "Membership", href: "/Billing", icon: CreditCardIcon, current: false },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];
const tabs = [{ name: "Recently Added", href: "#", current: true }];

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    LoadingSpinner,
    BellIcon,
    MenuIcon,
    SearchIcon,
    XIcon,
  },
  setup() {
    const availableToHire = ref(true);
    const privateAccount = ref(false);
    const allowCommenting = ref(true);
    const allowMentions = ref(true);

    return {
      user,
      navigation,
      subNavigation,
      userNavigation,
      availableToHire,
      privateAccount,
      allowCommenting,
      allowMentions,
      tabs,
    };
  },
  data() {
    return {
      ocData: null,
      userData: null,
      videoList: [],
      isLoading: false,
      isPremium: null,
      showSuccess: false,
      showFailure: false,
    }
  },
  async created() {
    this.isLoading = true;
    await this.fetchOcData();
    await this.fetchUserData();
    await this.populateVideoListData();
    this.isLoading = false;
  },
  methods: {
    async fetchOcData() {
    },
    async fetchUserData() {
      let id = CookieService.getLoginCookie()
      if (id) {
        this.userData = await NopService.GetCustomerDetailsById(id);
        this.isPremium = this.userData.IsPremium;
      }
      else {
        this.emitter.emit("logged-in", false);
      }
    },
    populateVideoListData() {
      let startindex = 0;
      // first, see how many videos we need
      for (let i = 0; i < this.userData.MaxNumberOfVideos; i++) {
        this.videoList.push({
          type: "",
          id: i,
          title: "",
          videoId: "",
          thumbnail: "",
        });
      }

      //next, fill it with whatever data there is
      if (this.userData.YoutubeVideoIds) {
        //update the item videoList[startindex]
        let youtubeData = this.userData.YoutubeVideoIds.split(";");
        for (let i = 0; i < youtubeData.length; i++) {
          let data = youtubeData[i];
          if (data) {
            this.createVideoItem(startindex, "youtube", data)
            startindex ++;
          }
        }
      }

      if (this.userData.VimeoVideoIds) {
        //update the item videoList[startindex]
        let vimeoData = this.userData.VimeoVideoIds.split(";");
        for (let i = 0; i < vimeoData.length; i++) {
          let data = vimeoData[i];
          if (data) {
            this.createVideoItem(startindex, "vimeo", data)
            startindex ++;
          }
        }
      }
    },
    async createVideoItem(index, type, data) {
      let splitData = data.split(":");
      let videoId = splitData[0];
      let videoTitle = "";//splitData[1];
      //https://img.youtube.com/vi/[video-id]/[thumbnail-number].jpg
      let thumbnail = ""
      if (type == "youtube") {
        thumbnail = "https://img.youtube.com/vi/" + videoId + "/0.jpg"
      }
      else if (type == "vimeo") {
        thumbnail = await this.getVimeoThumbnail(videoId);
      }
      this.videoList[index] = {
        type: type,
        title: videoTitle,
        videoId: videoId,
        id: index,
        thumbnail: thumbnail,
      };
    },
    validateVideoFields() {
      // go through the video list and make sure all fields are valid
      for (let i = 0; i < this.videoList.length; i++) {
        let current = this.videoList[i];
        if (current.videoId || 
            //current.title || 
            current.type) {
          // if there is a video id, title, or type, this means the user modified it
          // so they meant to include some data.
          if (!(current.videoId && 
              // current.title && 
              // !current.title.includes(';') && 
              current.type)) {
            return false;
          }
        }
      }
      return true;
    },
    updateVideoInList(videoIndex, field, value) {
      this.videoList[videoIndex][field] = value;
    },
    convertToVideoString() {
      //convert video data to be in the form of id:title;
      let youtubeString = "";
      let vimeoString = "";
      this.videoList.forEach(video => {
        if (video.type == "youtube") {
          youtubeString += video.videoId + ";"; //":" + video.title + ";"
        }
        else if (video.type == "vimeo") {
          vimeoString += video.videoId + ";"; //":" + video.title + ";"
        }
      });
      return [youtubeString, vimeoString];
    },
    async saveVideoList() {
      this.isLoading = true;
      this.showSuccess = false;
      this.showFailure = false;
      if (this.validateVideoFields()) {
        let stringResult = this.convertToVideoString();
        let youtubeString = stringResult[0];
        let vimeoString = stringResult[1];

        this.userData["isActive"] = 1;
        this.userData.YoutubeVideoIds = youtubeString;
        this.userData.VimeoVideoIds = vimeoString;
        let resp = await NopService.UpdateCustomer(this.userData)
        if (resp.Status === 1) {
          this.showSuccess = true;
        }
        else {
          this.showFailure = true;
        }
      }
      this.isLoading = false;
    },
    async getVimeoThumbnail(videoId) {
      let api = "https://vimeo.com/api/v2/video/" + videoId + ".json";
      let resp = await axios.get(api);
      return resp.data[0].thumbnail_small;
    },
    clearDataForVideo(videoId) {
      this.videoList[videoId] = {
          type: "",
          id: videoId,
          title: "",
          videoId: "",
          thumbnail: "",
        }
    },
  }
};
</script>
